import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import startCase from "lodash/startCase"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import tagDescriptions from "../content/tag-descriptions.json"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import { tagCard, tagCardBody, tagCardButton, tagCardPostCount } from "../styles/tagList.module.css"


const TagsPage = ({ data }) => {
  const { group } = data.allMarkdownRemark

  return (
    <Layout>
      <Container fluid className="text-white my-5">
        <Row className="justify-content-center">
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="text-center lead">
            <h1>Top Tags</h1>
            <Row className="justify-content-center">
              {group
                .sort((a, b) => (b.totalCount > a.totalCount) ? 1 : (a.totalCount === b.totalCount) ? ((a.fieldValue > b.fieldValue) ? 1 : -1) : -1)
                .map(tag => {
                  const tagDescriptionIndex = tagDescriptions.content.findIndex(
                    tagGroup => tagGroup.tag === startCase(tag.fieldValue)
                  )
                  return (
                    <Col key={tag.fieldValue} xs={12} sm={6} xl={4}>
                      <Card className={[tagCard]}>
                        {/* <Card.Img variant="top" src="icon.png" height={100} /> */}
                        <Card.Body className={[tagCardBody]}>
                          <Card.Title>
                            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className={[tagCardBody]}>
                              {startCase(tag.fieldValue)}
                            </Link>
                          </Card.Title>
                          <Card.Text className="mb-2">
                            {tagDescriptions.content[tagDescriptionIndex].description}
                          </Card.Text>
                          <Card.Text className={[tagCardPostCount]}>
                            {tag.totalCount} {tag.totalCount === 1 ? "post" : "posts"} published
                          </Card.Text>
                          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                            <Button variant="outline-primary" className={[tagCardButton]}>
                              Find {startCase(tag.fieldValue)} {tag.totalCount === 1 ? "Post" : "Posts"}
                            </Button>
                          </Link>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = (props) => {
  const pageSeo = {
    title: "My Digital Garden Blog Tags",
    description: "Get motivated to make a change in your life and become a better software engineer with some practical programming advice through quick blog posts.",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`